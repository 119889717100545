import { Ad, Layout } from "components";
import { deleteDoc, doc, updateDoc } from "firebase/firestore";
import { deleteObject, ref } from "firebase/storage";
import { useDataContext } from "providers";
import { Link, useNavigate, useParams, Navigate } from "react-router-dom";
import {
  useFirestore,
  useStorage,
  useStorageDownloadURL,
  useUser,
} from "reactfire";
import { Recipe, Step, User } from "types/collection";
import { COLLECTION_SET } from "utils/constants";
import { generateAddress } from "utils/misc";
import routes, { generateRoute } from "utils/routes";

const StepCard = ({ description, image, order }: Step) => {
  const storage = useStorage();
  // Ensure we're not using a root reference
  const coverRef = ref(storage, image || 'steps/default.png');
  const { data: coverUrl } = useStorageDownloadURL(coverRef);

  return (
    <div key={order}>
      <img
        src={coverUrl}
        alt={description}
        className="w-full rounded-lg my-3"
      />
      <div>{description}</div>
    </div>
  );
};

const RelatedCard = ({ galleries, title, id }: Recipe) => {
  const storage = useStorage();
  // Ensure we're not using a root reference
  const galleryPath = galleries && galleries.length > 0 ? galleries[0] : 'galleries/default.png';
  const coverRef = ref(storage, galleryPath);
  const { data: coverUrl } = useStorageDownloadURL(coverRef);

  return (
    <Link to={`/recipe/${id}`}>
      <img
        src={coverUrl}
        alt={title}
        className="w-full h-56 object-cover rounded-lg my-3"
      />
      <div className="text-xl font-bold text-gray-900">{title}</div>
    </Link>
  );
};

interface CoverProps {
  image: string;
  first?: boolean;
}

const RecipeCover = ({ image, first }: CoverProps) => {
  const storage = useStorage();
  // Ensure we're not using a root reference
  const coverRef = ref(storage, image || 'galleries/default.png');
  const { data: coverUrl } = useStorageDownloadURL(coverRef);

  return (
    <img
      src={coverUrl}
      alt="Recipe Gallery"
      className={`${first ? "col-span-3" : ""} w-full rounded-lg`}
    />
  );
};

interface Props {
  recipeData: Recipe;
  userData: User;
}

const RecipeDetailInner = ({ recipeData, userData }: Props) => {
  const navigate = useNavigate();
  const { data: user } = useUser();
  const { recipeId } = useParams();
  const { recipeList, userList } = useDataContext();
  const currentUser = userList.find((i) => i.email === user?.email);

  const { galleries, title, authorId, description, ingredients, steps, knack } =
    recipeData;
  const { avatar, id, nickname, area, prefecture } = userData;

  const firestore = useFirestore();
  const storage = useStorage();
  // Ensure we're not using a root reference
  const avatarRef = ref(storage, avatar || 'avatars/default.png');
  const { data: avatarUrl } = useStorageDownloadURL(avatarRef);

  const relatedRecipeList = recipeList.filter(
    (recipe) => recipe.authorId === id && recipe.id !== recipeId
  );
  const relatedData =
    relatedRecipeList.length > 2
      ? relatedRecipeList.slice(0, 3)
      : recipeList.slice(0, 3);

  const handleLike = async () => {
    if (currentUser) {
      const userRef = doc(firestore, COLLECTION_SET.USERS, currentUser.id);
      await updateDoc(userRef, {
        favourites: currentUser.favourites.includes(recipeData.id)
          ? currentUser.favourites.filter((i) => i !== recipeData.id)
          : [...currentUser.favourites, recipeData.id],
      });
    }
  };

  const handleDelete = async () => {
    if (currentUser) {
      const revipeRef = doc(firestore, COLLECTION_SET.RECIPES, recipeData.id);
      navigate(routes.home);
      await deleteDoc(revipeRef);
      recipeData.galleries.forEach(async (gallery) => {
        const galleryRef = ref(storage, gallery);
        await deleteObject(galleryRef);
      });
      recipeData.steps.forEach(async ({ image }) => {
        const galleryRef = ref(storage, image);
        await deleteObject(galleryRef);
      });
    }
  };

  return (
    <Layout title="Recipe">
      <div className="max-w-7xl mx-auto px-16 pt-12 grid grid-cols-4 border-x-2">
        <div className="col-span-4 md:col-span-3">
          <div className="flex justify-between">
            <h1 className="text-xl md:text-4xl font-bold text-gray-900">
              {title}
            </h1>
            {currentUser ? (
              <div>
                <button
                  className="mx-2 font-medium text-yellow-500 hover:text-yellow-400"
                  onClick={handleLike}
                >
                  {currentUser.favourites.includes(recipeData.id)
                    ? "Unlike"
                    : "Like"}
                </button>
                {currentUser.id === authorId ? (
                  <button
                    className="mx-2 font-medium text-yellow-500 hover:text-yellow-400"
                    onClick={handleDelete}
                  >
                    Delete
                  </button>
                ) : null}
              </div>
            ) : null}
          </div>

          <div className="grid grid-cols-4 my-6 gap-x-4">
            {galleries[0] ? <RecipeCover image={galleries[0]} first /> : null}
            {galleries.length > 1 ? (
              <div className="flex flex-col gap-y-4">
                {galleries.slice(1).map((i) => (
                  <RecipeCover key={i} image={i} />
                ))}
              </div>
            ) : null}
          </div>

          <div className="flex flex-col md:flex-row gap-x-16 gap-y-8 md:items-center">
            <div className="flex gap-x-5 items-center">
              <Link to={generateRoute(id, "profile")}>
                <img
                  src={avatarUrl}
                  alt={nickname}
                  className="w-16 rounded-full"
                />
              </Link>
              <div className="shrink-0">
                <h2 className="text-lg font-bold text-gray-900">{nickname}</h2>
                <span className="text-xs text-gray-600">
                  {generateAddress(area, prefecture)}
                </span>
              </div>
            </div>
            <p>{description}</p>
          </div>

          {/* Ingredients */}
          <div className="my-10">
            <h2 className="text-lg md:text-3xl font-bold text-gray-900 border-b-2 py-4">
              材料
            </h2>
            <ul className="grid grid-cols-1 md:grid-cols-3 list-disc p-4 gap-y-4">
              {ingredients.map((ingredient) => (
                <li key={ingredient}>{ingredient}</li>
              ))}
            </ul>
          </div>

          {/* Steps */}
          <div className="my-10">
            <h2 className="text-lg md:text-3xl font-bold text-gray-900 border-b-2 py-4">
              調理方法
            </h2>
            <div className="grid grid-cols-1 md:grid-cols-3 py-4 gap-6">
              {steps.map((step) => (
                <StepCard key={step.order} {...step} />
              ))}
            </div>
          </div>

          {/* Tips */}
          <div className="my-10">
            <h2 className="text-lg md:text-3xl font-bold text-gray-900 border-b-2 py-4">
              コツ
            </h2>
            <div className="py-4">{knack}</div>
          </div>

          {/* Related */}
          <div className="my-10">
            <h2 className="text-lg md:text-3xl font-bold text-gray-900 border-b-2 py-4">
              関連レシピ
            </h2>
            <div className="grid grid-cols-1 md:grid-cols-3 py-4 gap-6">
              {relatedData.map((recipe) => (
                <RelatedCard key={recipe.id} {...recipe} />
              ))}
            </div>
          </div>
        </div>
        <div className="flex flex-col items-center md:items-end col-span-4 md:col-span-1">
          <Ad />
        </div>
      </div>
    </Layout>
  );
};

const RecipeDetail = () => {
  const { recipeId } = useParams();
  const { recipeList, userList } = useDataContext();
  const recipeData = recipeList.find((i) => i.id === recipeId);
  const userData = userList.find((i) => i.id === recipeData?.authorId);

  return userData && recipeData ? (
    <RecipeDetailInner userData={userData} recipeData={recipeData} />
  ) : (
    <Navigate replace to={routes.notFound} />
  );
};

export default RecipeDetail;
