import { Ad, Layout, MyPagination, ProfileLayout } from "components";
import { useProfileContext } from "components/ProfileLayout";
import { ref } from "firebase/storage";
import { useDataContext } from "providers";
import { useState } from "react";
import { Link } from "react-router-dom";
import { useStorage, useStorageDownloadURL } from "reactfire";
import { User } from "types/collection";
import { ALL_PER_SECTION, Menu } from "utils/constants";
import { generateAddress } from "utils/misc";
import { generateRoute } from "utils/routes";

interface RecipeCardProps {
  url: string;
}

const RecipeCard = ({ url }: RecipeCardProps) => {
  const storage = useStorage();
  // Ensure we're not using a root reference
  const coverRef = ref(storage, url || 'galleries/default.png');
  const { data: avatarUrl } = useStorageDownloadURL(coverRef);

  return (
    <img
      src={avatarUrl}
      alt={url}
      className="w-full h-20 rounded-lg object-cover"
    />
  );
};

interface Props {
  user: User | undefined;
}

const FriendCard = ({ user }: Props) => {
  const { recipeList } = useDataContext();
  const storage = useStorage();
  // Ensure we're not using a root reference
  const avatarRef = ref(storage, (user && user.avatar) ? user.avatar : 'avatars/default.png');
  const { data: avatarUrl } = useStorageDownloadURL(avatarRef);

  if (user) {
    const myRecipeList = recipeList.filter((i) => i.authorId === user.id);
    return (
      <Link to={generateRoute(user.id, "profile")}>
        <div className="border-2 p-2 rounded-lg">
          <div className="grid grid-cols-2 gap-2">
            {Array(4)
              .fill(0)
              .map((_, index) =>
                myRecipeList[index] ? (
                  <RecipeCard
                    url={myRecipeList[index].galleries[0]}
                    key={index}
                  />
                ) : (
                  <div
                    className="w-full h-20 border-2 rounded-lg"
                    key={index}
                  ></div>
                )
              )}
          </div>
          <div className="mt-3 flex items-center gap-x-6">
            <img
              src={avatarUrl}
              alt={user.nickname}
              className="w-16 rounded-full"
            />
            <div>
              <h2 className="text-lg font-bold text-gray-900">
                {user.nickname}
              </h2>
              <p className="text-gray-400">
                {generateAddress(user.area, user.prefecture)}
              </p>
            </div>
          </div>
        </div>
      </Link>
    );
  } else {
    return null;
  }
};

const FriendsInner = () => {
  const { followings } = useProfileContext();
  const { userList } = useDataContext();

  const [page, setPage] = useState(1);
  const handlePageChange = (_: any, value: number) => {
    setPage(value);
  };

  const friendRawData = followings.map((i) => userList.find((x) => x.id === i));
  const length = Math.ceil(friendRawData.length / ALL_PER_SECTION);
  const friendData = friendRawData.slice(
    (page - 1) * ALL_PER_SECTION,
    page * ALL_PER_SECTION
  );

  return (
    <div className="px-16 grid grid-cols-4 py-16">
      <div className="mb-10 md:mt-0 col-span-4 md:col-span-3">
        <div className="grid gird-cols-1 md:grid-cols-3 gap-8">
          {friendData.map((friend) => (
            <FriendCard user={friend} key={friend?.email} />
          ))}
        </div>
        <MyPagination count={length} page={page} onChange={handlePageChange} />
      </div>
      <div className="flex flex-col items-center md:items-end col-span-4 md:col-span-1">
        <Ad />
      </div>
    </div>
  );
};

const Friends = () => {
  return (
    <Layout title="Friends">
      <ProfileLayout path={Menu.Friends}>
        <FriendsInner />
      </ProfileLayout>
    </Layout>
  );
};

export default Friends;
