import { Disclosure, Menu as HLMenu, Transition } from "@headlessui/react";
import { MenuIcon, XIcon } from "@heroicons/react/outline";
import { SearchIcon, ViewGridIcon } from "@heroicons/react/solid";
import { signOut } from "firebase/auth";
import { ref } from "firebase/storage";
import { useDataContext } from "providers";
import { Fragment, useState } from "react";
import { useForm } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";
import { useAuth, useStorage, useStorageDownloadURL, useUser } from "reactfire";
import logoImage from "static/img-wango-icon.png";
import { DEFAULT_AVATAR, Menu } from "utils/constants";
import routes, { generateRoute } from "utils/routes";
import Filter from "./Filter";

function classNames(...classes: any[]) {
  return classes.filter(Boolean).join(" ");
}

type FormData = {
  query: string;
};

export default function Header() {
  const { userList } = useDataContext();
  const { data: user } = useUser();
  const userData = user ? userList.find((i) => i.email === user.email) : null;

  const userNavigation = userData
    ? [
        { name: "Profile", to: generateRoute(userData.id, Menu.Profile) },
        {
          name: "Favourites",
          to: generateRoute(userData.id, Menu.Favourites),
        },
        {
          name: "My Recipes",
          to: generateRoute(userData.id, Menu.MyRecipe),
        },
        { name: "Recipe List", to: routes.all },
        { name: "Sign Out", to: "#" },
      ]
    : [
        { name: "Recipe List", to: routes.all },
        { name: "Sign In", to: routes.signin },
      ];

  const navigate = useNavigate();
  const auth = useAuth();

  const handleSignOut = async () => {
    await signOut(auth);
    navigate(routes.signin);
  };

  const handleAuthClick = () => {
    handleSignOut();
  };

  const storage = useStorage();
  const avatarPath = userData
    ? userData.avatar === DEFAULT_AVATAR.BIG
      ? DEFAULT_AVATAR.SMALL
      : userData.avatar
    : DEFAULT_AVATAR.SMALL;
  
  // Ensure we're not trying to use a root reference
  const avatarRef = ref(storage, avatarPath || 'avatars/avatar.png');
  const { data: avatarUrl } = useStorageDownloadURL(avatarRef);

  const [modal, setModal] = useState(false);
  const handleClick = () => {
    setModal(true);
  };

  const { register, handleSubmit } = useForm<FormData>();
  const onSubmit = handleSubmit((data) => {
    navigate(`${routes.all}?query=${data.query}`);
  });

  return (
    <Disclosure as="header" className="bg-white sticky top-0 z-10">
      {({ open }) => (
        <>
          <div className="flex-none max-w-7xl mx-auto px-2 sm:px-4 lg:divide-y lg:divide-gray-200 lg:px-16 shadow">
            <div className="relative h-16 flex justify-between">
              {/* Logo   */}
              <Link
                to={routes.home}
                className="relative z-10 px-2 flex lg:px-0"
              >
                <div className="flex-shrink-0 flex items-center bg-green-700 px-4">
                  <img
                    className="block h-8 w-auto"
                    src={logoImage}
                    alt="Wango Logo"
                  />
                </div>
              </Link>

              {/* Search */}
              <div className="relative z-0 flex-1 px-2 flex items-center justify-center sm:absolute sm:inset-0">
                <form className="w-full sm:max-w-xs" onSubmit={onSubmit}>
                  <label htmlFor="search" className="sr-only">
                    Search
                  </label>
                  <div className="relative">
                    <div className="pointer-events-none absolute inset-y-0 left-0 pl-3 flex items-center">
                      <SearchIcon
                        className="h-5 w-5 text-gray-400"
                        aria-hidden="true"
                      />
                    </div>
                    <input
                      id="query"
                      className="block w-full bg-white border border-gray-300 rounded-md py-2 pl-10 pr-3 text-sm placeholder-gray-500 focus:outline-none focus:text-gray-900 focus:placeholder-gray-400 focus:ring-1 focus:ring-green-600 focus:border-green-600 sm:text-sm"
                      placeholder="レシピについて検索してみよう"
                      type="search"
                      {...register("query")}
                    />
                  </div>
                </form>
                <button onClick={handleClick}>
                  <ViewGridIcon
                    className="block h-8 w-8 ml-3 text-green-700"
                    aria-hidden="true"
                  />
                </button>
              </div>

              <div className="relative z-10 flex items-center lg:hidden">
                {/* Mobile menu button */}
                <Disclosure.Button className="rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:bg-gray-100 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
                  <span className="sr-only">Open menu</span>
                  {open ? (
                    <XIcon className="block h-6 w-6" aria-hidden="true" />
                  ) : (
                    <MenuIcon className="block h-6 w-6" aria-hidden="true" />
                  )}
                </Disclosure.Button>
              </div>

              {/* Left */}
              <div className="hidden lg:relative lg:z-10 lg:ml-4 lg:flex lg:items-center">
                <Link
                  to={routes.post}
                  className="flex-shrink-0 bg-green-700 px-3 py-1 rounded text-gray-100 hover:bg-green-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-600"
                >
                  レシピ投稿
                </Link>

                {/* Profile dropdown */}
                <HLMenu as="div" className="flex-shrink-0 relative ml-5">
                  <div>
                    <HLMenu.Button className="bg-white rounded-full flex focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-600">
                      <img
                        className="h-8 w-8 rounded-full"
                        src={avatarUrl}
                        alt="Avatar"
                      />
                    </HLMenu.Button>
                  </div>
                  <Transition
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                  >
                    <HLMenu.Items className="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 py-1 focus:outline-none">
                      {userNavigation.map((item) => (
                        <HLMenu.Item key={item.name}>
                          {({ active }) => (
                            <Link
                              to={item.to}
                              onClick={
                                item.to === "#" ? handleAuthClick : undefined
                              }
                              className={classNames(
                                active ? "bg-gray-100" : "",
                                "block py-2 px-4 text-sm text-gray-700"
                              )}
                            >
                              {item.name}
                            </Link>
                          )}
                        </HLMenu.Item>
                      ))}
                    </HLMenu.Items>
                  </Transition>
                </HLMenu>
              </div>
            </div>
          </div>

          <Disclosure.Panel as="nav" className="lg:hidden" aria-label="Global">
            <div className="border-t border-gray-200 pt-4 pb-3">
              <div className="px-4 flex items-center">
                <div className="flex-shrink-0">
                  <img
                    className="h-10 w-10 rounded-full"
                    src={avatarUrl}
                    alt=""
                  />
                </div>
                <div className="ml-3">
                  <div className="text-base font-medium text-gray-800">
                    {userData?.nickname}
                  </div>
                  <div className="text-sm font-medium text-gray-500">
                    {userData?.email}
                  </div>
                </div>
              </div>
              <div className="mt-3 px-2 space-y-1">
                {userNavigation.map((item) => (
                  <Disclosure.Button
                    key={item.name}
                    className="block rounded-md py-2 px-3 text-base font-medium text-gray-500 hover:bg-gray-50 hover:text-gray-900"
                  >
                    <Link
                      to={item.to}
                      onClick={item.to === "#" ? handleAuthClick : undefined}
                    >
                      {item.name}
                    </Link>
                  </Disclosure.Button>
                ))}
              </div>
            </div>
          </Disclosure.Panel>

          <Filter open={modal} setOpen={setModal} />
        </>
      )}
    </Disclosure>
  );
}
