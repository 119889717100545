import { HeartIcon, PlusIcon } from "@heroicons/react/solid";
import { Ad } from "components";
import { doc, updateDoc } from "firebase/firestore";
import { ref } from "firebase/storage";
import { FC } from "react";
import { Link } from "react-router-dom";
import { useFirestore, useStorage, useStorageDownloadURL } from "reactfire";
import { Recipe, User } from "types/collection";
import { COLLECTION_SET } from "utils/constants";
import routes from "utils/routes";

interface CardProps extends Recipe {
  currentUser?: User;
}

const Card = ({
  id,
  galleries,
  title,
  description,
  currentUser,
}: CardProps) => {
  const firestore = useFirestore();
  const storage = useStorage();
  // Ensure we're not using a root reference
  const galleryPath = galleries && galleries.length > 0 ? galleries[0] : 'galleries/default.png';
  const coverRef = ref(storage, galleryPath);
  const { data: coverUrl } = useStorageDownloadURL(coverRef);

  const handleLike = async (e: any) => {
    e.preventDefault();
    if (currentUser) {
      const userRef = doc(firestore, COLLECTION_SET.USERS, currentUser.id);
      await updateDoc(userRef, {
        favourites: currentUser.favourites.includes(id)
          ? currentUser.favourites.filter((i) => i !== id)
          : [...currentUser.favourites, id],
      });
    }
  };

  return (
    <div key={id}>
      <Link
        to={`/recipe/${id}`}
        className={currentUser ? "block relative" : ""}
      >
        <img
          src={coverUrl}
          alt={title}
          className="w-full rounded-lg h-56 object-cover"
        />
        {currentUser ? (
          <HeartIcon
            className={`block h-8 w-8  absolute right-3 bottom-3 ${
              currentUser.favourites.includes(id)
                ? "text-red-600"
                : "text-gray-100"
            }`}
            aria-hidden="true"
            onClick={handleLike}
          />
        ) : null}
      </Link>
      <h3 className="text-1xl font-bold text-gray-900 my-3">{title}</h3>
      <p className="line-clamp-2">{description}</p>
    </div>
  );
};

interface Props {
  title?: string;
  recipeList: (Recipe | undefined)[];
  add?: boolean;
  currentUser?: User;
  noAd?: boolean;
}

const Section: FC<Props> = ({
  title,
  recipeList,
  children,
  add,
  currentUser,
  noAd,
}) => {
  return (
    <div className="p-16 grid grid-cols-4">
      <div className="mb-10 md:mb-0 col-span-4 md:col-span-3">
        {/* Header */}
        {title ? (
          <div className="flex justify-between items-center">
            <h2 className="text-md md:text-2xl font-bold text-gray-900">
              {title}
            </h2>
            <Link
              to={routes.all}
              className="text-sm md:font-medium text-yellow-500 hover:text-yellow-400"
            >
              View More →
            </Link>
          </div>
        ) : null}

        {/* Container */}
        <div
          className={`grid grid-cols-1 md:grid-cols-3 gap-8 ${
            title ? "mt-5" : ""
          }`}
        >
          {add ? (
            <Link
              to={routes.post}
              className="flex justify-center items-center h-60 cursor-pointer border-2 border-dotted"
            >
              <PlusIcon
                className="block h-8 w-8 text-gray-400"
                aria-hidden="true"
              />
            </Link>
          ) : null}
          {recipeList
            ? recipeList.map((recipe) =>
                recipe ? (
                  <Card key={recipe.id} {...recipe} currentUser={currentUser} />
                ) : null
              )
            : null}
        </div>
        {children}
      </div>
      <div
        className={`flex flex-col items-center md:items-end col-span-4 md:col-span-1 ${
          title ? "mt-12" : "mt-0"
        }`}
      >
        {noAd ? null : <Ad />}
      </div>
    </div>
  );
};

export default Section;
